<template>
  <!-- 我的医生页面-->
  <div class="doctor">
    <span v-if="doctor.length == 0" class="noDoctor">暂无关注医生</span>
    <div class="doctor-item" v-for="(item, index) in doctor" :key="index">
      <div class="doctor-img">
        <img
          src="@/assets/img/yisheng.png"
          v-if="item.portraitUrl == null || item.portraitUrl == ''"
          class="doctor-img"
          alt=""
          @click="doctorPage(item)"
        />
        <img
          :src="item.portraitUrl"
          v-else
          class="doctor-img"
          alt=""
          @click="doctorPage(item)"
        />
      </div>
      <div class="doctor-info">
        <div class="doctor-base-info">
          <span class="doctor-name" @click="doctorPage(item)">{{
            item.doctorName
          }}</span>
          <span class="doctor-deptName" @click="doctorPage(item)">{{
            item.doctorTitle
          }}</span>
          <span class="collect"> 收藏 </span>
          <i
            class="iconfont icon-xinaixin"
            :class="item.concern == false ? 'disactive' : 'active'"
            @click="toCollect(item)"
          ></i>
          <div class="doctor-dept" @click="doctorPage(item)">
            {{ item.deptName }}
          </div>
        </div>
        <!-- <div class="doctor-tel" @click="doctorPage(item)">
            <div class="fl">
              <img src="@/assets/img/guide/dingwei.png" alt="" class="icon" />
              <span>{{ item.deptPlace == null ? '暂无':item.deptPlace }}</span>
            </div>
            <div class="fr">
              <img src="@/assets/img/guide/rili.png" alt="" class="icon" />
              <span style="padding-right: 20px">周三、周六全天</span>
            </div>
          </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
export default {
  data() {
    return {
      currentSort: true,
      doctor: [],
    };
  },
  methods: {
    doctorPage(item) {
      sessionStorage.setItem("currentDoctor", JSON.stringify(item));
      this.$router.push({
        path: "doctorPage",
      });
    },
    async toCollect(item) {
      let postData = {
        userid: sessionStorage.getItem("openid"),
        doctorId: item.doctorCode,
        deptCode: item.deptCode,
      };
      if (!item.concern) {
        // 收藏
        await this.$http.post("/api/favorite/add", postData);
        Notify({
          message: "已收藏",
          color: "#fff",
          background: "#00D48B",
        });
      } else {
        // 取消收藏
        await this.$http.put("/api/favorite/cancle", postData);
        Notify({
          message: "取消收藏",
          color: "#fff",
          background: "#C6C6C6",
        });
      }
      item.concern = !item.concern;
      this.getDataList();
    },
    getDataList() {
      let postData = {
        userid: sessionStorage.getItem("openid"),
      };
      this.$http.get("/api/favorite/list", postData).then((res) => {
        if (res.status == 200) {
          this.doctor = res.data;
        }
      });
    },
  },
  mounted() {
    this.getDataList();
  },
};
</script>
<style scoped>
@import url(//at.alicdn.com/t/font_2783444_ka1zwfgai2j.css);
.body {
  height: 4rem;
  background-image: linear-gradient(#57fdc4, #00d48b);
}
.icon {
  width: 1em !important;
  height: 1em;
  fill: currentColor;
  overflow: hidden;
}
.doctor-item {
  width: 9rem;
  height: 2.6rem;
  background-color: #fff;
  margin: auto;
  border-radius: 15px;
  position: relative;
  top: 0.2rem;
  margin-top: 0.2rem;
}
.doctor-img {
  width: 2rem;
  float: left;
}
.doctor-img img {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 5px;
  margin-top: 10px;
  border-radius: 50%;
}
.doctor-info {
  width: 7rem;
  height: inherit;
  float: left;
}
.doctor-base-info {
  width: 7rem;
  height: 1.4rem;
  text-align: left;
  border-bottom: 1px solid #e6e6e6;
  font-size: 0.4rem;
  margin-top: 20px;
}
.doctor-dept {
  text-align: left;
  margin-top: 0.1rem;
}
.doctor-name {
  color: #333333;
  padding-right: 15px;
  font-weight: bold;
}
.doctor-deptName {
  color: #333333;
}
.collect {
  float: right;
  font-size: 0.4rem;
  padding-right: 15px;
  color: #a4a5ab;
}
.icon-xinaixin {
  float: right;
  font-size: 0.45rem !important;
  padding-top: 2px;
  padding-right: 3px;
}
.active {
  color: #00d48b !important;
}
.disactive {
  color: #c6c6c6 !important;
}
.doctor-tel {
  font-size: 0.4rem;
  text-align: left;
}
.doctor-tel div {
  margin-top: 0.1rem;
}
.icon {
  width: 0.35rem;
  height: 0.35rem;
  padding-right: 3px;
}
.good-icon {
  width: 0.4rem;
  height: 0.4rem;
  padding-right: 3px;
  position: relative;
  top: 2px;
}
.good-info {
  width: 9rem;
  margin: 0.2rem auto;
}
.good-title {
  font-size: 0.42rem;
  text-align: left;
}
.good-content {
  margin-top: 0.2rem;
  font-size: 0.4rem;
  text-align: left;
  line-height: 0.7rem;
  text-indent: 0.7rem;
}
.line {
  width: 9rem;
  height: 1px;
  background-color: rgba(212, 212, 212, 1);
  margin: 0.1rem auto;
}
.noDoctor {
  font-size: 0.4rem;
  margin-top: 0.3rem;
  display: block;
}
</style>
